import React, { Component } from 'react';
import "./styles/App.css";
import { Dubber } from "./pages/dubber";



class App extends Component {
  render() {
    return (
            <Dubber />
    );
  }
}

export default App;
